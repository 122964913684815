<template>
  <div class="page-authorizer">
    <h2 class="p-title">公众号编辑</h2>
    <yos-tab-nav :list="tabNav" />
    <bread-crumbs :list="breadList" />
    <div class="p-card">
      <div class="card-title">公众号信息</div>
      <div class="avatar">
        <img :src="authorizer.headImg" />
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">名称</span>
        <div class="right-wrapper">{{ authorizer.nickName }}</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">微信号</span>
        <div class="right-wrapper">{{ authorizer.alias }}</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">原始ID</span>
        <div class="right-wrapper">{{ authorizer.userName }}</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">类型</span>
        <div class="right-wrapper">{{ authorizer.serviceType }}</div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">授权状态</span>
        <div class="right-wrapper">
          {{ authorizer.status === 1 ? '正常' : '失效' }}
          <yos-button @click="setAuthorizationNeedParams">重新授权</yos-button>
        </div>
      </div>
      <div class="card-title no-top">参数设定</div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">店铺ID</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="authorizer.storeId"
            placeholder="不填写代表空值"
            maxlength="20"
          />
        </div>
      </div>
      <div class="left-fixed-item fix">
        <span class="left-fixed">消息备注扩展</span>
        <div class="right-wrapper">
          <yos-input
            v-model.trim="authorizer.templateMessageRemark"
            placeholder="不填写代表空值"
            maxlength="100"
          />
        </div>
      </div>
      <bottom-options @save="onSave" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import YosTabNav from '../../components/other/tab-nav'
import BreadCrumbs from '@/components/common/bread-crumbs'
import BottomOptions from '@/components/common/bottom-options'
import {
  getAuthorizer,
  updateAuthorizerStoreId,
  getAuthorizationNeed
} from '../../data'
import { useServer, useToast, useTabNav } from '../../hook'
import { useRoute } from 'vue-router'
import { gotToWeixin } from '../../util'

export default {
  components: {
    YosTabNav,
    BreadCrumbs,
    BottomOptions
  },
  setup() {
    const state = reactive({
      authorizer: {}
    })
    const route = useRoute()
    const toast = useToast()
    const { id } = route.params
    const nav = [{
      name: '公众号列表',
      to: '/setting/authorizers',
      active: true
    }]
    const breadList = [{
      name: '公众号列表',
      to: '/setting/authorizers'
    }, {
      name: '公众号信息',
    }]

    const setAuthorizerParams = useServer(getAuthorizer, (data) => {
      state.authorizer = data
    })

    const setAuthorizationNeedParams = useServer(getAuthorizationNeed, gotToWeixin)

    const setUpdateAuthorizerStoreIdParams = useServer(updateAuthorizerStoreId, ({ msg }) => {
      toast(msg)
    })

    const onSave = () => {
      if (typeof state.authorizer.templateMessageRemark === 'string' && state.authorizer.templateMessageRemark.length > 100) {
        toast('最多输入100个字符')
        return
      }

      setUpdateAuthorizerStoreIdParams({ id, storeId: state.authorizer.storeId || '', templateMessageRemark: state.authorizer.templateMessageRemark || '' })
    }

    setAuthorizerParams({ id })

    return {
      tabNav: useTabNav(nav),
      ...toRefs(state),
      breadList: ref(breadList),
      setAuthorizationNeedParams,
      onSave
    }
  }
}
</script>

<style lang="postcss">
.page-authorizer {
  .avatar {
    text-align: center;
    padding: 20px;

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.card-title {
  background-color: #f6f8f9;
  line-height: 40px;
  color: #555;
  padding: 0 10px;

  &.no-top {
    margin-top: 20px;
  }
}

.p-card {
  .left-fixed-item {
    line-height: 40px;
    margin-top: 10px;

    .yos-input {
      width: 90%;

      .yos-input-inner {
        width: 90%;
      }
    }

    .left-fixed {
      width: 110px;
      float: left;
      text-align: right;
      padding: 0 10px;
    }

    .right-wrapper {
      margin-left: 110px;

      &.right-box {
        width: 500px;
        min-height: 300px;
        border: solid 1px #e5e5e5;
        padding: 10px 0;
      }
    }
  }
}
</style>
